.grid-2-resize-template {
  grid-template-columns: 1fr !important;
  gap: 4px !important;

  > * {
    grid-column: 1 !important;
    grid-row-start: auto !important;
    grid-row-end: auto !important;
  }
}

@media (max-width: 1400px) {
  .grid-2-resize {
    .grid-2-resize-template();
  }
}
//
//@container (max-width: 1200px) {
//  // PARENT MUST HAVE: "container-type: inline-size;" in order for this to work
//  .grid-2-resize {
//    .grid-2-resize-template();
//  }
//}


.grid-1 {
  display: grid;
  grid-template-columns: 1fr !important;
  gap: 10px !important;

  > * {
    grid-column: 1 !important;
    grid-row-start: auto !important;
    grid-row-end: auto !important;
  }
}

.grid-2-resize {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.grid-auto-2 {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
