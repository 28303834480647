@import "colors.less";

html, body {
  height: 100%;
  width: 100%;
}

.bold {
  font-weight: bold;
}

[ui-view="mainPanel"] {
  height: 100%;
}

[ui-view="panelsContainer"], [ui-view="subModuleContainer"] {
  height: 100%;
}

[ui-view="main"] {
  height: ~"calc(100% - 45px - 12px)";

  & > .ng-scope {
    height: 100%;
  }
}

.layout-container {
  padding-top: 20px;
}

.navbar-brand {
  color: #4F5C69;
}

.ac-secondary-navbar {
  margin-top: 25px;
  width: ~"calc(100% + 40px)";
  margin-left: -20px;
  padding: 0 20px;
}

.ac-navbar-toolbar > .ac-button-menu a.dropdown-toggle {
  top: 5px;
  margin-right: 15px;
}

.gridStyle {
  border: 1px solid rgb(212, 212, 212);
}

.verticalSeparator(@height) {
  height: @height;
  display: block;
}

.verticalSeparator20 {
  .verticalSeparator(20px);
}

.verticalSeparator50 {
  .verticalSeparator(50px);
}

.padding20 {
  padding: 20px;
}

.drop-shadow-gray-border {
  border: 1px solid #d0d4d9;
  //box-shadow: 3px 3px 6px #d0d4d9;
}

.view-panel {
  position: relative;
}

.main-view-panel {
  .view-panel;
  background-color: #ffffff;
}

[ng-transclude] {
  height: 100%;
}

.half-screen {
  width: 50% !important;
  display: block;
}

.ac-page-actions {
  gap: 5px;
  > ul {
    list-style-type: none;
  }
}

.ac-page-dropdown-menus {
  float: left;
  margin-right: 7px;
}

.ac-page-dropdown-menus .btn-group {
  display: block;
}

.ac-page-dropdown-enus > ul {
  list-style-type: none;
}

.ac-page-toolbar {
  gap: 5px;
  position: relative;
  overflow: auto;
}

.ac-page-toolbar .ac-search-container {
  width: 200px;
}

.ac-page-actions > ul,
.ac-page-dropdown-menus > ul {
  padding-left: 0;
}

.ac-hover-info {
  .status-label {
    text-transform: capitalize;
  }
}

.details-view-panel {
  .view-panel;
  min-width: 368px;
  max-width: 368px;
  background-color: #ffffff;
  border: 1px solid #d0d4d9;

  .status-label {
    text-transform: capitalize;
  }
}

.details-view-container {
  width: 294px;
}

.filter-view-panel {
  background-color: #ffffff;
  position: relative;
}

.vertical-tab-content-row {
  display: block;
}

.vertical-tab-content-cell-8 {
  display: inline-block;
  width: ~"calc(67% - 133px)";
}

.vertical-tab-content-cell-4 {
  display: inline-block;
  width: ~"calc(33% - 167px)";
}

ul.ac-page-toolbar {
  position: relative;
  float: left;
  display: inline;
  list-style-type: none;
  transform: translateY(-50%);
  margin-right: 12px;
  padding: 0;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

ul.ac-page-toolbar li {
  display: inline;
}

div.ac-page-toolbar div.toolbar-search {
  display: inline-block;
  width: 200px;

  > .ac-button {
    display: inline-block;
    background: white;
    color: #7e7e7e;
    border: solid 1px #A8A8A8;
    width: 28px;
    padding: 3px 8px 2px 7px;
  }

  > .ac-button:hover {
    background: @text;
    color: white;
    border: solid 1px white;
  }

  .ac-search {
    display: inline-block;
  }
}

nav {
  &.drop-shadow-gray-border {
    height: 50px;
    border: none;
    box-shadow: none;
    //TODO erez
    position: relative;
  }

  .tab-title {
    position: relative;
    color: @text;
    text-transform: uppercase;
    display: inline-block;
    width: 218px;
    font-size: 17px;
    padding-top: 15px;
  }
}

.toolbar-actions-menu {
  display: flex;
  gap: 5px;

  ac-button {
    white-space: nowrap;
  }

  .ac-button-menu {
    height: 27px;
    position: relative;
    top: -1px;

    .action-icon {
      padding-right: 3px;
    }
  }
}

.toolbar-discrete-actions-menu {
  display: inline-block;
  margin-left: 30px;
}

b {
  &.warning-note {
    color: @redStatus;
  }
}

.single-selection-detail-value {
  font-size: 14px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.audiocodes-logo-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ac-svg {
    width: 140px;
  }
}

.mat-divider-horizontal{
  margin: 15px 10px!important;
}


